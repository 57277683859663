import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import "./Section.css";
import CheckBoxesGeneric from "./CheckboxesGeneric";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

const PlanProducts = (props) => {
  const [token] = useState(localStorage.getItem("agentToken"));
  const [productTypes, setProductTypes] = useState([]);
  const [productTypeID, setProductTypeID] = useState(props.productType_id);
  const [products, setProducts] = useState([]);
  const [productID, setProductID] = useState(props.product_id);
  const [product, setProduct] = useState({});
  const [productDosage, setProductDosage] = useState(props.dosage);
  const [weeks, setWeeks] = useState(props.weeks);
  const [,setNumOfWeeks] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [choices, setChoices] = useState([]);
  const [BGColor, setBGColor] = useState("#F6F6F6");
  const [standardDosage, setStandardDosage] = useState(0);
  const [able, setAble] = useState(
    props.planDetail.orderStatus_id > 1 ? false : true
  );
  const [planProduct,] = useState(props.planProduct);  

  const getOGDosage = () => {
    let choice = props.choices.filter(
      (choice) =>
        choice.category_id === props.category_id &&
        choice.productType_id === props.productType_id &&
        choice.product_id === props.product_id
    );
    
    setStandardDosage(choice[0].dosage);
  };
  const getProducts = async (productTypeID) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      "/api/all_products_by_productType_id/" + productTypeID,
      requestOptions
    );

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setProducts([...data]);
      var tempProduct = data.filter(
        (product) => product.id === props.product_id
      );
      setProduct(tempProduct[0]);
      getOGDosage();
    }
  };

  const getProductTypes = async (category_id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      "/api/all_productTypes_by_category_id/" + category_id,
      requestOptions
    );

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setProductTypes([...data]);
    }
  };

  const getAllChoices = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/all_choices/", requestOptions);

    const data = await response.json();
    if (!response.ok) {
      //console.log('There is an error')
    } else {
      setChoices([...data]);
    }
  };

  useEffect(() => {
    setNumOfWeeks(weeks.filter(Boolean).length);

    setTotalVolume(
      productDosage *
        props.plantingDetail.farmSize *
        weeks.filter(item=>item.val === true).length
    );
  }, [weeks]);

  useEffect(() => {
    setNumOfWeeks(weeks.filter(Boolean).length);

    setTotalVolume(
      productDosage *
        props.plantingDetail.farmSize *
        weeks.filter(item=>item.val === true).length
    );
  }, [productDosage]);

  useEffect(() => {
    let isMounted = true; 
    if(isMounted){
      getProductTypes(props.category_id);
      getAllChoices();
      getProducts(props.productType_id);
      setTotalVolume(
        productDosage *
          props.plantingDetail.farmSize *
          weeks.filter(item=>item.val === true).length
      );
    }
    
    return () => {
      isMounted = false; 
    };
  }, []);

  const handleFirstSelection = (e) => {
    setProductID("");
    setProductTypeID(e.value);
    getProducts(e.value);
  };

  const handleSecondSelection = (e) => {
    setProductID(e.value);
    var thisProduct = products.filter((product) => product.id === e.value);
    setProduct(thisProduct[0]);
    setProductDosage(thisProduct[0]["dosage"]);
  };

  const deletePlanProduct = async () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      "/api/plan_products/" + props.plan_product_id,
      requestOptions
    );

    if (!response.ok) {
    } else {
      props.setProductChange(!props.productChange);
      props.setSelection([]);
    }
  };

  const setCardToUpdate = (e) => {
    e.preventDefault();
    setAble(false);
    setBGColor("#FFFFFF");
  };

  const updatePlanProduct = async () => {
    var choiceID = choices.filter(
      (choice) =>
        choice.category_id === props.category_id &&
        choice.productType_id === productTypeID &&
        choice.product_id === product.id
    );
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        plan_id: props.planDetail.id,
        dosage: productDosage,
        weeks: JSON.stringify(weeks),
        totalVolume:
          productDosage *
          props.plantingDetail.farmSize *
          weeks.filter(item=>item.val === true).length,
        choice_id: choiceID[0].id,
        crop_id: props.cropId
      }),
    };
    const response = await fetch(
      "/api/update_plan_product/" + props.plan_product_id,
      requestOptions
    );

    if (!response.ok) {
      //console.log('There is an error')
    } else {
      props.setPlanProductChange(true);
      setAble(true)
    }
  };

  const cardHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Button
          icon="pi pi-book"
          label="WYSIG"
          iconPos="top"
          disabled={!able}
          style={{ color: "#000000", marginRight: "1%" }}
          className="p-button-text"
          onClick={setCardToUpdate}
        />
        <Button
          icon="pi pi-trash"
          label="VEE UIT"
          iconPos="top"
          disabled={!able}
          style={{ color: "#000000", marginRight: "1%" }}
          className="p-button-text"
          onClick={deletePlanProduct}
        />
      </div>
    );
  };

  const flexColumnStyle = {
    display: "flex",
    flexDirection: "column",
    width: "20%",
    paddingLeft: "1%",
  };
  const totalStyle = {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1%",
    width: "18%",
  };
  const totalDosageStyle = {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1%",
    width: "22%",
  };
  const flexColumnDosageStyle = {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1%",
    width: "20%",
  };
  const flexRowStyle = { display: "flex", flexDirection: "row", width: "100%" };
  const flexContainerColumnStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: BGColor,
    marginBottom: "1%",
  };
  return (
    <Card style={flexContainerColumnStyle} header={cardHeader}>
      <div style={flexRowStyle}>
        <div style={flexColumnStyle}>
          <h4>Teiken</h4>
          <Dropdown
            optionLabel="name"
            optionValue="id"
            value={productTypeID}
            disabled={true}
            options={productTypes}
            onChange={handleFirstSelection}
            placeholder={"VOEG " + props.category_name + " BY"}
          />
        </div>

        {productTypeID !== "" ? (
          <>
            <div style={flexColumnStyle}>
              <h4>Produk</h4>

              {/* Other product description           */}
              {products.find((item) => item.id === productID && item.isParent === true) ? (
              <InputText
                id="other_product_name"
                value={planProduct.product_name}
                disabled={true}
                
              />)
              : 
              // Standard product - dropdown
              (<Dropdown
                style={{ width: "100%" }}
                optionLabel="name"
                optionValue="id"
                disabled={true}
                value={productID}
                options={products}
                onChange={handleSecondSelection}
                placeholder={"Kies Produk"}
              />)
              }
            </div>
          </>
        ) : (
          <></>
        )}

        {productID !== "" ? (
          <>
            <div style={flexColumnDosageStyle}>
              <h4>Dosis ({product.unit}/Ha)</h4>
              <InputText
                id="dosage"
                value={productDosage}
                disabled={able}
                onChange={(e) => {
                  setProductDosage(e.target.value);
                }}
              />
              {parseFloat(productDosage) !== standardDosage && !product?.isParent ? (
                <small id="dosage-help" className="p-error block">
                  *Dosis is aangepas. Raadpleeg die produketiket om te verseker
                  dat die dosis binne registrasie is.
                </small>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        {productID !== "" ? (
          <>
            <div style={totalStyle}>
              <h5>
                Totale Volume ={" "}
                {totalVolume
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                {product.unit}
              </h5>
            </div>
          </>
        ) : (
          <></>
        )}

        {productID !== "" ? (
          <>
            <div style={totalDosageStyle}>
              <h5>
                Totale Dosis per Hektaar={" "}
                {(totalVolume / props.plantingDetail.farmSize)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                {product.unit}
              </h5>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <CheckBoxesGeneric
        weeks={weeks}
        setWeeks={setWeeks}
        disabled={props.planDetail.orderStatus_id > 1 ? true : able}
        productDosage = {productDosage}
        product = {product}
      />

      {!able ? (
        <>
          <Button
            disabled={props.planDetail.orderStatus_id > 1}
            label={"Dateer op: " + product.name}
            style={{
              width: "100%",
              marginTop: "2%",
              backgroundColor: props.buttonColor,
              borderColor: props.buttonColor,
            }}
            onClick={updatePlanProduct}
          ></Button>
        </>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default PlanProducts;

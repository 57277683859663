import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import OrderProductCard from "./OrderProductCard";
import Header from "../../Header";
import "./OrderView.css";
import PastOrderCard from "../Past Orders/PastOrderCard";
import { CSSTransition } from "react-transition-group";
import PlanDetailsAPI from "../../../API/PlanDetailsAPI";

const OrderView = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token] = useState(localStorage.getItem("agentToken"));
  const [orderProducts, setOrderProducts] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [allPlans, setAllPlans] = useState([]);

  const updatePlanStatus = (planId, isSelected) => {
    let allPlansCopy = [...allPlans];
    var currentPlan = allPlansCopy.find((i) => i.id === planId);
    currentPlan.selected = false;
    setAllPlans(allPlansCopy);
  };

  const onHide = () => {
    setErrorMessage("");
    setShowDialog(false);
  };

  const onShow = () => {
    setShowDialog(true);
  };

  const submitLogin = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(`grant_type=&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&scope=&client_id=&client_secret=`),
    };

    const response = await fetch("/api/token", requestOptions);
    const data = await response.json();

    if (!response.ok) {
      setErrorMessage(data.detail);
    } else {
      if (data.access_token === token) {
        setErrorMessage("Epos of wagwoord is verkeerd");
        props.setBackPath("orderView");
        props.setPath("priceView");
      } else {
        setErrorMessage("Invalid token. Please log out and sign in again.");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitLogin();
  };

  const getOrderProducts = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch("/api/get_order_products_by_plan_id/" + props.planDetails.id, requestOptions);
    const data = await response.json();
    var res = [];
    for (var index in data) {
      var oProduct = data[index];
      oProduct["packages"] = JSON.parse(oProduct["packages"]);
      oProduct["name"] = oProduct?.product_name
      oProduct["isParent"] = oProduct?.productsRel?.isParent
      res.push(oProduct);
    }
    setOrderProducts([...res]);
  };

  //moving to centralised service layer
  const getAllPlans = async () => {
    PlanDetailsAPI.getAllPlans(false, props?.agentDetail?.id, props.cropId).then((data) => {
      if (props.filterPlan && props.filterPlan > 0) {
        data = data.filter((i) => i.id === props.filterPlan);
      }
      setAllPlans([...data]);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getOrderProducts();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Header title={"BESTELLING"} 
      backPath={"planView"} 
      setPath={props.setPath} />
      <div className="container mt-3 ms-0">
        <CSSTransition timeout={1000}>
          <PastOrderCard 
          planDetails={props.planDetails} 
          getAllPlans={getAllPlans} 
          isHeader={true} 
          updatePlanStatus={updatePlanStatus} />
        </CSSTransition>
      </div>
      <h5 className="text-center" style={{color:"#00a352"}}>Hersien die volumes per verpakking. Met die bestelling word verpakking na volpak volumes opgerond.</h5>
      <table className="table w-100 ps-3 pe-30  me-4 ms-4">
        <thead>
          <tr>
            <th style={{textAlign: "center"}} scope="col">PRODUK</th>
            <th style={{textAlign: "center"}} scope="col">PLAN VOL</th>
            <th style={{textAlign: "center"}} scope="col">BESTEL VOL</th>
            <th style={{textAlign: "center"}} scope="col">VERSKIL</th>
            <th style={{textAlign: "center"}} scope="col">VERPAKKING</th>
          </tr>
        </thead>
        <tbody>
          {orderProducts.sort((a,b)=>a.name.localeCompare(b.name)).map(function (product) {
            return (
              <tr key={`${Math.random().toString(36).substr(2)}-${Math.random().toString(36).substr(2)}`}>
                <OrderProductCard
                  key={product.id}
                  name={product.name}
                  id={product.id}
                  getOrderProducts={getOrderProducts}
                  orderVolume={product.orderVolume}
                  planVolume={product.planVolume}
                  packages={product.packages}
                  qtys={product.qtys}
                  planDetails={props.planDetails}
                  unit={product.productsRel.unit}
                  isParentProduct = {product.isParent}
                />
              </tr>
            );
          })}
        </tbody>
      </table>

      
      <div className="row mt-5">
        <div className="col-lg-6 text-lg-end text-sm-center">
          <Button
            style={{ width: "35%" }}
            className="p-button-text order_buttons mt-3 h-75 ms-lg-2"
            onClick={() => {
              props.setPath("pastOrders");
            }}
          >
             Stoor bestelling en gaan terug na tuisblad
          </Button>
        </div>
        <div className="col-lg-6  text-lg-start text-sm-center">
          <Button 
            style={{ width: "35%" }} 
            className="p-button-text order_buttons mt-3 h-75 ms-lg-2" 
            onClick={onShow}>
            Gaan voort na bestelling met pryse en waardes
          </Button>
        </div>
      </div>

      <Dialog 
        header="TEKEN IN" 
        visible={showDialog} 
        className="Dialog" 
        modal 
        onHide={onHide}>
        <form onSubmit={handleSubmit} style={{ width: "100%", height: "90%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-end",
              alignContent: "center",
              alignSelf: "center",
            }}
          >
            <label className="small-info" style={{ fontSize: "1.25em" }}>
              Teken in om pryse en waardes te besigtig.​
            </label>
          </div>
          <span 
            className="p-float-label" 
            style={{ marginBottom: "5vh", marginTop: "5vh" }}>
            <InputText id="Email" 
              style={{ width: "100%" }} 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} />
            <label htmlFor="Email">EPOS</label>
          </span>
          <span className="p-float-label" style={{ marginBottom: "5vh", marginTop: "5vh" }}>
            <InputText id="Password" type="password" style={{ width: "100%" }} value={password} onChange={(e) => setPassword(e.target.value)} />
            <label htmlFor="Password">WAGWOORD</label>
          </span>
          {errorMessage !== "" ? <p style={{ color: "red" }}>{errorMessage}</p> : <></>}
          {password !== "" && email !== "" ? (
            <>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "#00a352",
                  borderColor: "#00a352",
                }}
                type="submit"
                label="TEKEN IN"
              />
            </>
          ) : (
            <>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "#00a352",
                  borderColor: "#00a352",
                }}
                type="submit"
                disabled={true}
                label="TEKEN IN"
              />
            </>
          )}
        </form>
      </Dialog>
    </div>
  );
};

export default OrderView;

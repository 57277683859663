import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../context/UserContext";
import "../OrderView.css";
import { Button } from "primereact/button";
import { ORDER_STATUS } from "../../../../constants/OrderStatus";
import { ROLES } from "../../../../constants/Roles";
import PricingAPI from "../../../../API/PricingAPI";
import { ProgressSpinner } from "primereact/progressspinner";
import ConsolidatedOrderApi from "../../../../API/ConsolidatedOrderApi";
import Notification from "../../../Shared/Notification/Notification"

const SubmitConsolidatedOrderView = (props) => {
  const [token] = useContext(UserContext);
  const [planDetails] = useState(
    props.planDetailsPrevious !== null && props.planDetailsPrevious.length > 0
      ? props.planDetailsPrevious
      : props.planDetails
  );
  const [consolidatedOrders, setConsolidatedOrders] = useState([]);
  const [farmSizeTotal, setFarmSizeTotal] = useState(0);
  const [currentDate, setCurrentDate] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const error = "Bestelling ingedien vir goedkeuring";

  useEffect(() => {
    getAgentDetails();
    props.setPlanDetailsPrevious(null);
    const current = new Date();
    const currentDate = `${current.getFullYear()}-${
      current.getMonth() < 9 ? "0" : ""
    }${current.getMonth() + 1}-${
      current.getDate() < 10 ? "0" : ""
    }${current.getDate()}`;
    setCurrentDate(currentDate);
  }, []);

  useEffect(() => {
    if (planDetails !== undefined && planDetails.length > 0)
      getConsolidatedOrders(planDetails);
    else setConsolidatedOrders([]);
  }, [planDetails]);

  useEffect(() => {
    if (consolidatedOrders.length === 0) return;
    setFarmSizeTotal(
      Object.values(consolidatedOrders.plans).reduce(
        (r, { farmSize }) => r + farmSize,
        0
      )
    );
  }, [consolidatedOrders.length, consolidatedOrders]);

  const getAgentDetails = async () => {
    if (props.agentDetail && props.parentDetail) return;
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch("/api/agents/auth_agent", requestOptions);
    const agent = await response.json();
    if (response.ok) {
      props.setAgentDetail(agent);
      getParentAgentDetails(agent.parent_id);
    }
  };

  const getParentAgentDetails = async (id) => {
    if (id == null) return;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch("/api/get_agent/" + id, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      console.log(
        "There is an error on the SubmitConsolidatedView page fetching agent data"
      );
    } else {
      props.setParentDetail(data);
    }
  };

  const moveBack = async (status_id) => {
    props.setPath("pastOrders");
    props.setFilterPlan(null);
  };

  const getConsolidatedOrders = async (planDetails) => {
    if (planDetails === null || planDetails.length === 0) {
      setConsolidatedOrders(null);
      return;
    }
    setShowSpinner(true);
    PricingAPI.getOrderPricings(planDetails.map((i) => i.id))
      .then((data) => {
        data.plans = [
          ...data.plans,
          {
            circles: null,
            crop: null,
            cultivar: null,
            farmer_business_name: null,
            farmer_contact_person: null,
            farmer_email: null,
            farmer_farm_name: "Totaal",
            planting_date: null,
            planting_detail_id: null,
            products: null,
            registered: null,
            farmSize: null,
            planName: null,
            plan_add_weather_discount: 0,
            plan_id: null,
            plan_total_discount: data.order_totals.order_final_discount,
            plan_total_discount_price: data.order_totals.order_final_price,
            plan_total_discount_price_weather: 0,
            plan_total_discount_weather: 0,
            plan_total_list_price: data.order_totals.order_list_price,
          },
        ];

        let discountTotal = 0;
        data.product_summary.forEach((item) => {
          discountTotal =
            discountTotal +
            (100 *
              (item.list_price_pv -
                item.full_order_final_price /
                  (item.full_packs * item.full_pack_volume))) /
              item.list_price_pv;
        });
        discountTotal = discountTotal / data.product_summary.length;

        data.product_summary = [
          ...data.product_summary,
          {
            AGI: null,
            discount_rate: null,
            full_order_discount: data.order_totals.full_order_discount,
            full_order_final_price: data.order_totals.full_order_final_price,
            full_order_list_price: data.order_totals.full_order_list_price,
            full_pack_units: null,
            full_pack_volume: null,
            full_packs: null,
            full_quantity: null,
            full_volume: null,
            list_price_pv: "Average",
            order_final_discount: discountTotal,
            order_final_price: null,
            order_list_price: null,
            product_name: null,
            product_pack_name: null,
            quantity: null,
            unit: null,
            isParent: null
          },
        ];
        setConsolidatedOrders(data);
        setShowSpinner(false);
      })
      .catch(() => setShowSpinner(false));
  };

  const createConsolidatedOrder = async (status_id) => {
    props.planDetails.forEach((item) => (item.orderStatus_id = 3));
    props.setFilterPlan(null);
    const orderHeader = await createOrderHeader();
    if (orderHeader.id > 0) {
      await createOrderDetailPlans(orderHeader.id);
      //only after plan status's were updated
      moveBack(status_id);
      await createOrderDetailProducts(orderHeader.id);
      await createAgentOrderSubmissionEmail(orderHeader, [
        //Pieter wants email sent to agent disabled for now
        //ROLES.AGENT,
        ROLES["SALES MANAGER"],
      ]);
  
      const result = await ConsolidatedOrderApi.createCommercialTermMypackOrder(
       orderHeader.id
      );

      await updateConsolidatedOrderHeader(
        orderHeader.id,
        orderHeader.merchant_id,
        orderHeader.depot,
        orderHeader.dateCreated,
        orderHeader.orderStatus_id,
        orderHeader.invoiceValue,
        orderHeader.note,
        orderHeader.area,
        orderHeader.numberOfPrograms,
        orderHeader.archive ? orderHeader.archive : false,
        result
      );
    }
  };

  const createOrderHeader = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        merchant_id: 1,
        depot: props.agentDetail?.depot,
        dateCreated: new Date().getTime(),
        orderStatus_id: ORDER_STATUS.INGEDIEN,
        invoiceValue: consolidatedOrders.order_totals.full_order_final_price,
        area: farmSizeTotal,
        numberOfPrograms:
          consolidatedOrders.plans != null
            ? consolidatedOrders.plans.filter((i) => i.plan_id !== null).length
            : 0,
        note: "",
        crop_id: props.cropId
      }),
    };
    var url = "/api/create_consolidated_order_header/";
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (response.ok) {
      console.log("Insert of Order Header was successful");
      return data;
    } else {
      console.log("Insert of Order Header failed");
      return -1;
    }
  };

  const updateConsolidatedOrderHeader = async (
    id,
    merchant_id,
    depot,
    dateCreated,
    orderStatus_id,
    invoiceValue,
    note,
    area,
    numberOfPrograms,
    archive,
    cpqResponse
  ) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        merchant_id: merchant_id,
        depot: depot,
        dateCreated: dateCreated,
        orderStatus_id: orderStatus_id,
        invoiceValue: invoiceValue,
        note: note,
        area: area,
        numberOfPrograms: numberOfPrograms,
        archived: archive,
        cpqResponse: cpqResponse,
        crop_id: props.cropId
      }),
    };
  
    var url = "/api/update_consolidated_order_header/" + id;
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      console.log(
        "There was an error in SubmitConsolidatedOrderView.updateConsolidatedOrderHeader"
      );
    } else {
      console.log("Update ConsolidatedOrder Header was successful");
    }
  };

  const createAgentOrderSubmissionEmail = async (
    consolidatedOrderHeader,
    roles
  ) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        consolidatedOrderHeader: consolidatedOrderHeader,
        agent: props?.agentDetail,
        salesManager: props.parentDetail,
        //can't get this to work. Unprocessable entity on api side
        //plans: items = consolidatedOrderPlans
        roles: roles,
      }),
    };

    var url = "/api/create_and_send_submission_order/";
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (response.ok) {
      console.log("Email was successful");
      return data.id;
    } else {
      console.log("Email failed");
      return -1;
    }
  };

  const createOrderDetailPlans = async (consolidatedOrderHeader_id) => {
    var items = consolidatedOrders.plans
      .filter((i) => i.plan_id != null)
      .map((i) => ({
        consolidatedOrderHeader_id: consolidatedOrderHeader_id,
        planDetail_id: i.plan_id,
        listedValue: i.plan_total_list_price,
        discountValue: i.plan_total_discount,
        invoiceValue: i.plan_total_discount_price,
        plantingDetail_id: i.planting_detail_id,
      }));
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: ["{", '"items":', JSON.stringify(items), "}"].join(" "),
    };

    var url = "/api/create_consolidated_order_plans/";
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      console.log("Insert of order plans was successful");
      return true;
    } else {
      console.log("Insert of order plans failed");
    }
  };

  const createOrderDetailProducts = async (consolidatedOrderHeader_id) => {
    var items = consolidatedOrders.product_summary
      .filter((i) => i.AGI !== null)
      .map((i) => ({
        consolidatedOrderHeader_id: consolidatedOrderHeader_id,
        produk: i.product_name,
        agi_code: i.AGI,
        sku_code: "",
        listedPrice: i.list_price_pv,
        discount:
          i.list_price_pv -
          i.full_order_final_price / (i.full_packs * i.full_pack_volume),
        discountPercentage:
          (100 *
            (i.list_price_pv -
              i.full_order_final_price / (i.full_packs * i.full_pack_volume))) /
          i.list_price_pv,
        qty: i.quantity,
        qtyRounded: i.full_quantity,
        listedValue: i.full_order_list_price,
        discountValue: i.full_order_discount,
        invoiceValue: i.full_order_final_price,
      }));

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: ["{", '"items":', JSON.stringify(items), "}"].join(" "),
    };

    var url = "/api/create_consolidated_order_products/";
    const response = await fetch(url, requestOptions);

    if (response.ok) {
      console.log("Insert of order plans was successful");
      return true;
    } else {
      console.log("Insert of order plans failed");
    }
  };

  if (showSpinner || !consolidatedOrders)
    return (
      <>
        <div className="d-flex flex-column min-vh-100 min-vw-100">
          <div className="d-flex flex-grow-1 justify-content-center align-items-center">
            <ProgressSpinner />
          </div>
        </div>
      </>
    );

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <label
            style={{ marginTop: "2%", marginLeft: "2%", outline: "none" }}
            className="p-button-text consolidated_header_color"
          >
            <h1>Gekonsolideerde bestelling</h1>
            <p>{props.email}</p>
          </label>
          <hr />
        </div>
      </div>
      <div className="row">
        <h2
          style={{ marginLeft: "2%", outline: "none" }}
          className="p-button-text consolidated_header_color"
        >
          Saamgevoegde bestelling
        </h2>
      </div>

      <div className="row">
        <div className="col-lg-8">
          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <h4>Agent (Gewasadviseur)</h4>
            </div>
            <div className="col-lg-6">
              <h4>Syngenta Verkoopsbestuurder</h4>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <label>Naam: {props?.agentDetail?.name}</label>
            </div>
            <div className="col-lg-6">
              <label>Naam: {props.parentDetail?.name}</label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <label>Handelaar: {props?.agentDetail?.merchantRel?.name}</label>
            </div>
            <div className="col-lg-6">
              <label>Epos: {props.parentDetail?.email}</label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <label>Depot: {props?.agentDetail?.depot}</label>
            </div>
            <div className="col-lg-6">
              <label>Kontak nommer: {props.parentDetail?.contactNo}</label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <label>Epos: {props?.agentDetail?.email}</label>
            </div>
            <div className="col-lg-6">
              <p></p>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <label>Kontak nommer: {props?.agentDetail?.contactNo}</label>
            </div>
            <div className="col-lg-6">
              <label>Bestelling verwysing: </label>
            </div>
          </div>

          <div className="row" style={{ marginLeft: "5%", outline: "none" }}>
            <div className="col-lg-6">
              <p></p>
            </div>
            <div className="col-lg-6">
              <label>Bestel datum: {currentDate}</label>
            </div>
          </div>
        </div>

        <div className="col-lg-4">
          <div className="h-100 d-flex flex-column">
            <div className="row ms-5">
              <div className="col-lg-12">
                <Button
                  disabled={
                    props.userRoles == null || props.userRoles.length === 0
                  }
                  className={`p-button-text consolidated_order_buttons  w-50 disabled}`}
                  label="DIEN BESTELLING IN VIR GOEDKEURING"
                  iconPos="left"
                  onClick={() => {
                    setShowNotification(true);
                    setTimeout(()=>{
                      createConsolidatedOrder(ORDER_STATUS.INGEDIEN)
                    },2000);
                    
                  }
                  }
                />
              </div>
              <div className="col-lg-12">
                <Button
                  className="p-button-text consolidated_order_buttons consolidated_cancel_button mt-3 w-50"
                  label="KANSELLEER, GAAN TERUG "
                  iconPos="left"
                  onClick={() => {
                    moveBack(ORDER_STATUS.WAG);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h4
          style={{ marginLeft: "4%", outline: "none" }}
          className="p-button-text consolidated_header_color"
        >
          Spuitprogramme geselekteer
        </h4>
        <p className="p" style={{ marginLeft: "4%", outline: "none" }}>
          Neem kennis: Addisionele korting word aan bestellings kleiner as
          R2,000,000 toegeken indien die gesamentlike bestelling R2,000,000
          oorskry.
        </p>

        <table className="table w-75" style={{ marginLeft: "4%" }}>
          <thead>
            <tr style={{ color: "#FFFFFF", backgroundColor: "#00a352" }}>
              <th scope="col" style={{ width: "10%" }}>
                Spuitprogram verwysing
              </th>
              <th scope="col">Gewas</th>
              <th scope="col">Klient</th>
              <th scope="col">Plaas</th>
              <th scope="col">Kampe</th>
              <th scope="col">Aanplant datum</th>
              <th className="text-end" scope="col">
                Area (Ha)
              </th>
              <th className="text-end text-nowrap" scope="col">
                Lys Waarde
              </th>
              <th className="text-end text-nowrap" scope="col">
                Afslag Waarde
              </th>
              <th className="text-end text-nowrap" scope="col">
                MyPack Waarde
              </th>
              <th scope="col" className="text-end">
                Spuitprogram en bestelling
              </th>
            </tr>
          </thead>
          <tbody>
            {consolidatedOrders.plans ? (
              consolidatedOrders.plans.map((item, index) => {
                return (
                  <tr
                    key={Math.random()}
                    className={`${
                      item.plan_id === null ? "showBorder" : "noBorder"
                    }`}
                  >
                    <td>
                      {item.plan_id === null ? "" : item.plan_id}{" "}
                      {item.planName}
                    </td>
                    <td>{item.crop}</td>
                    <td>{item.farmer_business_name}</td>
                    <td>{item.farmer_farm_name}</td>
                    <td>{item.circles}</td>
                    <td>
                      {item?.planting_date != null
                        ? new Date(item?.planting_date)
                            ?.toISOString()
                            .split("T")[0]
                        : ""}
                    </td>
                    <td className="text-end">{item.farmSize}</td>
                    <td className="text-end text-nowrap">
                      {item.plan_total_list_price
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>

                    <td className="text-end text-nowrap">
                      {item.plan_total_discount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>

                    <td className="text-end text-nowrap">
                      {item.plan_total_discount_price
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>
                    <td>
                      <button
                        type="button"
                        className={`btn p-button p-component p-button-text consolidated_order_buttons  w-75 float-end ${
                          item.plan_id === null ? "d-none" : ""
                        }`}
                        onClick={() => {
                          item.planting_id =
                            item.plantingDetailId === undefined
                              ? item.planting_id
                              : item.plantingDetailId;
                          props.setBackPath("submitConsolidatedOrderView");

                          //to reset the collection of planDetails when moving back to this view
                          props.setPlanDetailsPrevious(planDetails);
                          let tempPlanDetails = [];
                          tempPlanDetails.push(item);
                          item.plantingDate = item.planting_date;
                          props.setPlanDetails([...tempPlanDetails]);
                        
                          props.setPath("individualProgramView");
                          props.setFilterPlan(item.sprayProgramReference);
                          //required to calculate the discount given where total order values are > 2 milion
                          if(props?.setOrderTotalListPrice === undefined || props?.setOrderTotalListPrice === null     )
                            return
                          props.setOrderTotalListPrice(
                            consolidatedOrders.plans.find(
                              (i) => i.farmer_farm_name === "Totaal"
                            ).plan_total_list_price
                          );
                        }}
                      >
                        Vertoon
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <div></div>
            )}
          </tbody>
        </table>
      </div>

      <div>
        <h4
          style={{ marginLeft: "4%", outline: "none" }}
          className="p-button-text consolidated_header_color"
        >
          Samevatting van die bestelling   
        </h4>
        <p className="p" style={{ marginLeft: "4%", outline: "none" }}>
          Neem kennis: Pryse en waardes is onderhewig aan finale goedkeuring
          deur Syngenta.
        </p>

        <table className="table w-75" style={{ marginLeft: "4%" }}>
          <thead>
            <tr style={{ color: "#FFFFFF", backgroundColor: "#00a352" }}>
              <th scope="col">Produk</th>
              <th scope="col">AGI</th>
              <th className="text-end" scope="col">
                Verpakking
              </th>
              <th className="text-end" scope="col">
                Bestel volume
              </th>
              <th className="text-end" scope="col">
                Aantal eenhede
              </th>
              <th className="text-end" scope="col">
                Volpak volume
              </th>
              <th className="text-end text-nowrap" scope="col">
                Lys Waarde
              </th>
              <th className="text-end  text-nowrap" scope="col">
                Afslag waarde
              </th>
              <th className="text-end  text-nowrap" scope="col">
                MyPack waarde
              </th>
              <th className="text-end" scope="col">
                MyPack prys (R/L)
              </th>
            </tr>
          </thead>
          <tbody>
          
            {consolidatedOrders.product_summary ? (
              consolidatedOrders.product_summary.filter((item)=> item?.isParent === 0).map((item, index) => {
                return (
                  <tr
                    key={Math.random()}
                    className={`${
                      item.list_price_pv === "Average"
                        ? "showBorder"
                        : "noBorder"
                    }`}
                  >
                    <td>
                      {item.product_name === "Totaal"
                        ? ""
                        : item.product_pack_name}
                    </td>
                    <td>{item.AGI}</td>
                    <td className="text-end">
                      {item.AGI == null
                        ? ""
                        : item.full_quantity !== null &&
                          item.full_quantity !== 0
                        ? (item.full_packs * item.full_pack_volume) /
                          item.full_quantity
                        : 0}{" "}
                      {item.unit !== null && item.unit.length > 1
                        ? item.unit
                        : ""}
                    </td>

                    <td className="text-end">
                      {item.AGI == null
                        ? ""
                        : item.full_pack_units != null &&
                          item.full_pack_units !== 0
                        ? ((item.full_pack_volume / item.full_pack_units) *
                          item.quantity).toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        : 0}{" "}
                      {item.unit !== null && item.unit.length > 1
                        ? item.unit
                        : ""}
                    </td>

                    <td className="text-end">
                      {item.AGI == null
                        ? ""
                        : (item.full_packs * item.full_pack_units).toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>
                    <td className="text-end">
                      {item.AGI == null
                        ? ""
                        : (item.full_packs * item.full_pack_volume).toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                      {item.unit !== null && item.unit.length > 1
                        ? item.unit
                        : ""}
                    </td>

                    <td className="text-end text-nowrap">
                      {parseFloat(item.full_order_list_price)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>
                    <td className="text-end">
                      {parseFloat(item.full_order_discount)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>
                    <td className="text-end">
                      {parseFloat(item.full_order_final_price)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    </td>
                    <td className="text-end">
                      {item.AGI == null ? "Korting: " : ""}
                      {(item.AGI == null
                        ? item.full_order_list_price !== null ||
                          item.full_order_list_price !== 0
                          ? (100 * item.full_order_discount) /
                            item.full_order_list_price
                          : 0
                        : parseFloat(
                            item.full_order_final_price / item.full_volume
                          )
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      {item.AGI == null ? "%" : ""}
                    </td>
                  </tr>
                );
              })
            ) : (
              <div></div>
            )}
          </tbody>
        </table>
        <h4
          style={{ marginLeft: "4%", outline: "none" }}
          className="p-button-text consolidated_header_color"
        >
          Indien en goedkeuring van die bestelling
        </h4>
        <p className="p w-75" style={{ marginLeft: "4%", outline: "none" }}>
          Met die indien van die bestelling, word die bestelling aan jou
          Syngenta verkoopsverteenwoordiger gestuur wat die bestelling hersien
          vir aanvanklike goedkeuring. Met goedkeuring word jou bestelling
          ingedien vir finale goedkeuring deur ‘n Syngenta Areabestuurder. Met
          finale goedkeuring sal jou Syngenta verkoopsverteenwoordiger jou met
          ‘n spesiale prysvorm voorsien om die bestelling deur te voer. Kontak
          gerus jou Syngenta verkoopsverteenwoordiger vir enige navrae of
          ondersteuning.
        </p>
      </div>
      {showNotification && <Notification typeOfMessage={"info"} message={error} close={() => setShowNotification(false)} />}
    </div>
  );
};

export default SubmitConsolidatedOrderView;

import React, { useState } from "react";
import "./Accordian.css";
import { useEffect } from "react";

const SummaryAccordian = (props) => {
  const [isActive, setIsActive] = useState(true);
  const [labels, setLabels] = useState([]);
  const [totalWeekArray, setTotalWeekArray] = useState([]);

  const colorCode = {
    ONKRUIDDODERS: "#5f5900",
    "SAADMOER BESKERMING": "#5f7800",
    SWAMDODERS: "#04b7eb",
    INSEKDODERS: "#000064",
    BYVOEGMIDDELS: "#009b2d",
    "PLANTGROEIREGULEERDERS (PGRs)": "#6366F1",
    "AKTIEWE BESTANDELE": "#A7171A",
    ANDER: "#ecb32a"
  };

  let buildInfo = (productTypes) => {
    let totalWeekArray = [];

    productTypes.forEach((productType) => {
      let pType = Object.keys(productType)[0];
      let products = Object.values(productType)[0];

      let productsStructureToArray = Object.entries(products);
      productsStructureToArray.forEach((item) => {
        let weekArray = [];

        //"Teiken", "Produk" and headers of weeks i.e. Teiken, Produk, Voor-Plant, Voor-Opkoms ....
        if (labels.length === 0) {
          setLabels(item[1].weekLabels ? item[1].weekLabels : ["No labels found"]);
        }

        //Product Type
        weekArray.push(pType);

        //item[0] is the product name
        weekArray.push(item[0]);

        //this is the data, an array where each item represents a value per period
        weekArray.push(...item[1].weeks);

        totalWeekArray.push(weekArray);
      });
    });

    /*
      an array that consists of items that are each a comma delimeted string. It compares the first string of each subarray with each other. 
      If it is the same, it looks at the second string of each array. The first string is the productType and the second, the product
    */

    //for now, I am commenting this out. Liana wants it in the order that the user created it. In the past, they wanted it alphabetically
    //   const sortedItems = [...totalWeekArray].sort((firstString, secondString) => {
    //   const firstElementComparison = firstString[0].localeCompare(secondString[0]);
    //   if (firstElementComparison !== 0) {
    //     return firstElementComparison;
    //   } else {
    //     return firstString[1].localeCompare(secondString[1]);
    //   }
    // });

    const sortedItems = [...totalWeekArray];
    setTotalWeekArray(sortedItems);
  };

  useEffect(() => {
    let keys = Object.keys(props.object);
    let res = [];
    for (var keyIndex in keys) {
      var key = keys[keyIndex];
      var item = {};
      item[key] = props.object[key];
      res.push(item);
    }
    buildInfo([...res]);
  }, []);
  const bgColor = colorCode[props.category_name];
 
  return (
    <div className="accordion-item" style={{ width: "100%" }}>
      <div className="accordion-item" style={{ width: "100%" }}>
        <div className="accordion-title" style={{ backgroundColor: bgColor, color: "#FFFFFF" }} onClick={() => setIsActive(!isActive)}>
          <div>
            {isActive ? <i className="pi pi-chevron-down"></i> : <i className="pi pi-chevron-right"></i>} {props.category_name}
          </div>
        </div>

        {isActive ? (
          <div className="container-fluid">
            <div className="row mx-3 my-2" name="theHeader">
              {labels.map((item, index) => {
                let colWidth = labels && labels.length > 0 ? (80 / labels.length).toString() + "%" : "0%";
                return (
                  <div
                    key={index}
                    className={`fw-bold ${"text-start"} text-overflow`}
                    style={{ width: index === 0 || index === 1 ? "10%" : colWidth }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
            {totalWeekArray.map(function (weeks) {
              return (
                <div className="row mx-3" name="theDetail">
                  {weeks.map(function (week, index) {
                    let colWidth = weeks && weeks.length > 0 ? (80 / weeks.length).toString() + "%" : "0%";
                    return (
                      <div
                        key={`${Math.random().toString(36).substr(2)}-${Math.random().toString(36).substr(2)}`}
                        className={`${"text-start"} text-overflow`}
                        style={{ width: index === 0 || index === 1 ? "10%" : colWidth }}
                      >
                        {week}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default SummaryAccordian;

import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import "./OrderView.css";
import { ORDER_STATUS } from "../../../constants/OrderStatus";
import Header from "../../Header";
import ConsolidatedOrderCard from "./Consolidated Orders/ConsolidatedOrderCard";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button } from "primereact/button";
import "./OrderView.css";
import LoadingSpinner from "../LoadingSpinner";

const OrderListView = (props) => {
  const [token,, agent] = useContext(UserContext);
  const [allOrders, setAllOrders] = useState([]);
  const [userRoles] = useState(props.userRoles);
  const [changeOrderListState, setChangeOrderListState] = useState(null);
  const [archived, toggleArchive] = useState(false);
  const [showSpinner,setShowSpinner] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if(isMounted){
      getAllOrders(props.cropId);
    }
    return ()=>{
      isMounted = false
    }    
  }, []);

  useEffect(() => {
    getAllOrders(props.cropId);
  }, [changeOrderListState]);

  const getAllOrders = async (cropId) => {
    setShowSpinner(true)
  
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    let response = null;
  
    try {
      if (userRoles.find((i) => i === "CAMPAIGN LEAD")) {
        response = await fetch(
          "/api/get_consolidated_order_headers_by_campaign_lead/" +
            agent.id +
            "/-1"+
            "/"+
            cropId,
          requestOptions
        );
      }
  
      if (userRoles.find((i) => i === "AREA MANAGER")) {
        response = await fetch(
          "/api/get_consolidated_order_headers_by_area_manager/" +
            agent.id +
            "/-1"+
            "/"+
            cropId,
          requestOptions
        );
      }
      if (userRoles.find((i) => i === "SALES MANAGER")) {
        response = await fetch(
          "/api/get_consolidated_order_headers_by_sales_manager/" +
            agent.id +
            "/-1"+
            "/"+
            cropId,
          requestOptions
        );
      }
  
      if (response === null && userRoles.find((i) => i === "AGENT")) {
        response = await fetch(
          "/api/get_consolidated_order_headers_by_agent/" 
          + agent.id + 
          "/-1"+
            "/"+
            cropId,
          requestOptions
        );
      }
  
      if (response == null) return;
      const data = await response.json();
      if (!response.ok) {
        console.log("There is an error - get_consolidated_order_headers_by_...");
      } else {
        setAllOrders([...data]);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      await new Promise(resolve => setTimeout(resolve, 5000)); 
      setShowSpinner(false)
    }
  };
  

  const onCropChange = (cropId) =>{
    getAllOrders(cropId);;
  }
  return (
    <div>
      <Header
        title={`LYS VAN BESTELLINGS`}
        backPath={`pastOrders`}
        setPath={props.setPath}
        crops={props.crops}
        setCropId={props.setCropId}
        cropId={props.cropId}
        onCropChange={onCropChange}
        agent={agent}
      >
        </Header>
          
      {showSpinner && 
      <LoadingSpinner loadingText={"Laai van bestellings ..."} topPercentage={30}/>
      }
      <div className="container mt-4">
        <div className="row">
          <div className={`col-lg-12 ${archived ? "d-none" : ""}`}>
            <Button
              className="button white_order_button p-button-label p-c m-1 archiving_navigation_buttons float-end"
              onClick={() => {
                toggleArchive(!archived);
              }}
            >
              ARGIEF
            </Button>
          </div>
          <div className={`col-lg-12 ${archived ? "" : "d-none"}`}>
            <Button
              icon="pi pi-arrow-left"
              iconPos="left"
              label="TERUG NA BESTELLINGS"
              className="white_order_button archiving_navigation_buttons p-button-label p-c m-1 float-end"
              style={{
                outline: "none",
              }}
              onClick={() => {
                toggleArchive(!archived);
              }}
            />
          </div>
        </div>

        <div className={`row ${archived ? "d-none" : ""}`}>
          <div className="col-lg-10">
            <details>
              <summary>
                <label
                  style={{ color: "rgb(0, 163, 82)" }}
                  className="fw-bolder"
                >
                  INGEDIEN
                </label>
              </summary>

              <TransitionGroup
                className="mt-5"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                }}
              >
                {allOrders
                  .filter((i) => i.orderStatus_id === 3)
                  .filter((i) => !i.archived)
                  .sort(
                    (a, b) =>
                      b.dateCreated - a.dateCreated ||
                      a.orderStatus_id - b.orderStatus_id
                  )
                  .map((item, index) => {
                    return (
                      <CSSTransition
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          alignSelf: "center",
                        }}
                        key={item.id}
                        timeout={1000}
                        unmountOnExit
                        classNames="my-node"
                      >
                        <ConsolidatedOrderCard
                          orderStatus={
                            Object.entries(ORDER_STATUS).find(
                              ([key, value]) => value === item.orderStatus_id
                            )?.length > 0
                              ? Object.entries(ORDER_STATUS).find(
                                  ([key, value]) =>
                                    value === item.orderStatus_id
                                )[0]
                              : "Invalid"
                          }
                          setPlanDetails={props.setPlanDetails}
                          setPath={props.setPath}
                          setConsolidatedOrderHeader={
                            props.setConsolidatedOrderHeader
                          }
                          dateCreated={
                            new Date(item.dateCreated)
                              .toISOString()
                              .split("T")[0]
                          }
                          invoiceValue={item.invoiceValue
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                          numberOfPrograms={
                            item.numberOfPrograms ? item.numberOfPrograms : 0
                          }
                          area={item.area ? item.area : 0}
                          userRoles={userRoles}
                          consolidatedOrderHeaderId={item.id}
                          note={item.note}
                          consolidatedOrderHeader={item}
                          setChangeOrderListState={setChangeOrderListState}
                          changeOrderListState={changeOrderListState}
                          archived={archived}
                          cpqResponse={item.cpqResponse}
                          cropId={props.cropId}
                        />
                      </CSSTransition>
                    );
                  })}
              </TransitionGroup>
            </details>

            <details>
              <summary>
                <label
                  style={{ color: "rgb(0, 163, 82)" }}
                  className="fw-bolder"
                >
                  GOEDGEKEUR
                </label>
              </summary>

              <TransitionGroup
                className="mt-5"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                }}
              >
                {allOrders
                  .filter((i) => i.orderStatus_id === 4)
                  .filter((i) => !i.archived)
                  .sort(
                    (a, b) =>
                      b.dateCreated - a.dateCreated ||
                      a.orderStatus_id - b.orderStatus_id
                  )
                  .map((item, index) => {
                    return (
                      <CSSTransition
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          alignSelf: "center",
                        }}
                        key={item.id}
                        timeout={1000}
                        unmountOnExit
                        classNames="my-node"
                      >
                        <ConsolidatedOrderCard
                          orderStatus={
                            Object.entries(ORDER_STATUS).find(
                              ([key, value]) => value === item.orderStatus_id
                            )?.length > 0
                              ? Object.entries(ORDER_STATUS).find(
                                  ([key, value]) =>
                                    value === item.orderStatus_id
                                )[0]
                              : "Invalid"
                          }
                          setPlanDetails={props.setPlanDetails}
                          setPath={props.setPath}
                          setConsolidatedOrderHeader={
                            props.setConsolidatedOrderHeader
                          }
                          dateCreated={
                            new Date(item.dateCreated)
                              .toISOString()
                              .split("T")[0]
                          }
                          invoiceValue={item.invoiceValue
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                          numberOfPrograms={
                            item.numberOfPrograms ? item.numberOfPrograms : 0
                          }
                          area={item.area ? item.area : 0}
                          userRoles={userRoles}
                          consolidatedOrderHeaderId={item.id}
                          note={item.note}
                          consolidatedOrderHeader={item}
                          setChangeOrderListState={setChangeOrderListState}
                          changeOrderListState={changeOrderListState}
                          archived={archived}
                          cropId={props.cropId}
                        />
                      </CSSTransition>
                    );
                  })}
              </TransitionGroup>
            </details>

            <details>
              <summary>
                <label
                  style={{ color: "rgb(0, 163, 82)" }}
                  className="fw-bolder"
                >
                  AFGEKEUR
                </label>
              </summary>

              <TransitionGroup
                className="mt-5"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                }}
              >
                {allOrders
                  .filter((i) => i.orderStatus_id === 5)
                  .filter((i) => !i.archived)
                  .sort(
                    (a, b) =>
                      b.dateCreated - a.dateCreated ||
                      a.orderStatus_id - b.orderStatus_id
                  )
                  .map((item, index) => {
                    return (
                      <CSSTransition
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          alignSelf: "center",
                        }}
                        key={item.id}
                        timeout={1000}
                        unmountOnExit
                        classNames="my-node"
                      >
                        <ConsolidatedOrderCard
                          orderStatus={
                            Object.entries(ORDER_STATUS).find(
                              ([key, value]) => value === item.orderStatus_id
                            )?.length > 0
                              ? Object.entries(ORDER_STATUS).find(
                                  ([key, value]) =>
                                    value === item.orderStatus_id
                                )[0]
                              : "Invalid"
                          }
                          setPlanDetails={props.setPlanDetails}
                          setPath={props.setPath}
                          setConsolidatedOrderHeader={
                            props.setConsolidatedOrderHeader
                          }
                          dateCreated={
                            new Date(item.dateCreated)
                              .toISOString()
                              .split("T")[0]
                          }
                          invoiceValue={item.invoiceValue
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                          numberOfPrograms={
                            item.numberOfPrograms ? item.numberOfPrograms : 0
                          }
                          area={item.area ? item.area : 0}
                          userRoles={userRoles}
                          consolidatedOrderHeaderId={item.id}
                          note={item.note}
                          consolidatedOrderHeader={item}
                          setChangeOrderListState={setChangeOrderListState}
                          changeOrderListState={changeOrderListState}
                          archived={archived}
                          cropId={props.cropId}
                        />
                      </CSSTransition>
                    );
                  })}
              </TransitionGroup>
            </details>

            <details>
              <summary>
                <label
                  style={{ color: "rgb(0, 163, 82)" }}
                  className="fw-bolder"
                >
                  GEKANSELLEER
                </label>
              </summary>

              <TransitionGroup
                className="mt-5"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                }}
              >
                {allOrders
                  .filter((i) => i.orderStatus_id === 6)
                  .filter((i) => !i.archived)
                  .sort(
                    (a, b) =>
                      b.dateCreated - a.dateCreated ||
                      a.orderStatus_id - b.orderStatus_id
                  )
                  .map((item, index) => {
                    return (
                      <CSSTransition
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          alignSelf: "center",
                        }}
                        key={item.id}
                        timeout={1000}
                        unmountOnExit
                        classNames="my-node"
                      >
                        <ConsolidatedOrderCard
                          orderStatus={
                            Object.entries(ORDER_STATUS).find(
                              ([key, value]) => value === item.orderStatus_id
                            )?.length > 0
                              ? Object.entries(ORDER_STATUS).find(
                                  ([key, value]) =>
                                    value === item.orderStatus_id
                                )[0]
                              : "Invalid"
                          }
                          setPlanDetails={props.setPlanDetails}
                          setPath={props.setPath}
                          setConsolidatedOrderHeader={
                            props.setConsolidatedOrderHeader
                          }
                          dateCreated={
                            new Date(item.dateCreated)
                              .toISOString()
                              .split("T")[0]
                          }
                          invoiceValue={item.invoiceValue
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                          numberOfPrograms={
                            item.numberOfPrograms ? item.numberOfPrograms : 0
                          }
                          area={item.area ? item.area : 0}
                          userRoles={userRoles}
                          consolidatedOrderHeaderId={item.id}
                          note={item.note}
                          consolidatedOrderHeader={item}
                          setChangeOrderListState={setChangeOrderListState}
                          changeOrderListState={changeOrderListState}
                          archived={archived}
                          cropId={props.cropId}
                        />
                      </CSSTransition>
                    );
                  })}
              </TransitionGroup>
            </details>
          </div>
        </div>

        <div className={`row ${archived ? "" : "d-none"}`}>
          <TransitionGroup
            className="mt-5"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              alignSelf: "center",
            }}
          >
            {allOrders
              .filter((i) => i.archived)
              .sort(
                (a, b) =>
                  b.dateCreated - a.dateCreated ||
                  a.orderStatus_id - b.orderStatus_id
              )
              .map((item, index) => {
                return (
                  <CSSTransition
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      alignSelf: "center",
                    }}
                    key={item.id}
                    timeout={1000}
                    unmountOnExit
                    classNames="my-node"
                  >
                    <ConsolidatedOrderCard
                      orderStatus={
                        Object.entries(ORDER_STATUS).find(
                          ([key, value]) => value === item.orderStatus_id
                        )?.length > 0
                          ? Object.entries(ORDER_STATUS).find(
                              ([key, value]) => value === item.orderStatus_id
                            )[0]
                          : "Invalid"
                      }
                      setPlanDetails={props.setPlanDetails}
                      setPath={props.setPath}
                      setConsolidatedOrderHeader={
                        props.setConsolidatedOrderHeader
                      }
                      dateCreated={
                        new Date(item.dateCreated).toISOString().split("T")[0]
                      }
                      invoiceValue={item.invoiceValue
                        .toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      numberOfPrograms={
                        item.numberOfPrograms ? item.numberOfPrograms : 0
                      }
                      area={item.area ? item.area : 0}
                      userRoles={userRoles}
                      consolidatedOrderHeaderId={item.id}
                      note={item.note}
                      consolidatedOrderHeader={item}
                      setChangeOrderListState={setChangeOrderListState}
                      changeOrderListState={changeOrderListState}
                      archived={archived}
                      cropId={props.cropId}
                    />
                  </CSSTransition>
                );
              })}
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default OrderListView;

import React, { useState, useEffect, useRef } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "./PlanView.css";
import SummaryTable from "./Components/SummaryTable";
import SummaryTablePrint from "./Components/SummaryTablePrint";
import { Button } from "primereact/button";
import Header from "../../Header";
import ProductSelect from "./Components/ProductSelect";
import download from "downloadjs";
import { CSSTransition } from "react-transition-group";
import PastOrderCard from "../Past Orders/PastOrderCard";
import IngredientsAPI from "../../../API/IngredientsAPI";
import Notification from "../../Shared/Notification/Notification"


const PlanView = (props) => {
  const [productInfo, setProductInfo] = useState(false);
  const [plantingDetail, setPlantingDetail] = useState({});
  const [productChange, setProductChange] = useState(false);
  const [proceedBool, setProceedBool] = useState(true);
  const [displayProducts, setDisplayProducts] = useState(false);
  const componentRef = useRef();
  const [planProductChange, setPlanProductChange] = useState(false);
  const [planProducts, setplanProducts] = useState([]);
  const [ingredientsUsed, setIngredientsUsed] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const error = "Plan met slegs 'ander' produkte word nie toegelaat nie";
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("agentToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    //todo - this should move to app.js so that it is only called once
    const getProductInfo = async () => {
      var response;
      response = await fetch(`/api/get_all_categories/${props.cropId}`, requestOptions);
      const categories = await response.json();
      response = await fetch(`/api/get_all_products_types/${props.cropId}`, requestOptions);
      const product_types = await response.json();
      setProductInfo({ categories: categories, product_types: product_types });
    };

    const getPlantingDetails = async (id) => {
      var url = "/api/planting_details/" + id;
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setPlantingDetail({ ...data[0] });
    };

    getProductInfo();
    getPlantingDetails(props.planDetails.planting_id);
  }, [props.planDetails.planting_id]);

  const getPlanProducts = async () => {
    const token = localStorage.getItem("agentToken");
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch("/api/get_plan_products_by_plan_id/" + props.planDetails.id, requestOptions);
    const data = await response.json();
    setplanProducts(data);
    return data;
  };

  const getIngredientsUsed = async (productPlanIds) => {
    IngredientsAPI.getPlanProductsPeriodIngredients(productPlanIds).then((data) => {
      setIngredientsUsed(data && data.length > 0 ? JSON.parse(data) : []);
    });
  };

  const getIngredients = async () => {
    IngredientsAPI.getAllIngredients().then((data) => {
      setIngredients(data);
    });
  };

  const buildProductInformation = async () => {
    const planProducts = await getPlanProducts();
    const productPlanIds = planProducts.map((productPlan) => productPlan.id);
    await getIngredientsUsed(productPlanIds);
    await getIngredients();
    var summary = {};
    for (const p of planProducts) {
      var productWeeks = p.weeks.split(",");
      const weeksInObj = JSON.parse(productWeeks);
      const choice = props.choices.find((choice) => choice.id === p.choice_id);
      const category = productInfo["categories"].find((category) => category.id === choice.category_id);
      const productType = productInfo["product_types"].find((pt) => pt.id === choice.productType_id);
      
      //change standard product name to "other" product name where applicable
      var product = props.products.find((product) => product.id === choice.product_id);
      product.name = p.product_name

      summary[category.name] = summary[category.name] || {};
      summary[category.name][productType.name] = summary[category.name][productType.name] || {};
      summary[category.name][productType.name][product.name] = summary[category.name][productType.name][product.name] || {};
      
      //create an empty weeks_dosage
      summary[category.name][productType.name][product.name]["weeks_dosage"] =
        summary[category.name][productType.name][product.name]["weeks_dosage"] || Array(weeksInObj.length).fill(0);
      const weeks = weeksInObj.map((value) => {
        return value.val;
      });

      const weeks_dosage0 = summary[category.name][productType.name][product.name]["weeks_dosage"];
      const weeks_dosage = weeks.map((w) => w * p.dosage);
      summary[category.name][productType.name][product.name]["weeks_dosage"] = weeks_dosage0.map((w, i) => w + weeks_dosage[i]);

      summary[category.name][productType.name][product.name]["weeks"] = summary[category.name][productType.name][product.name]["weeks_dosage"].map((d) =>
        d === 0 ? "" : `${d.toFixed(2)} ${product.unit}/Ha`
      );
      let weekLabels = weeksInObj.map((value) => {
        return value.desc.replace("\n", "");
      });

      weekLabels.unshift("Produk");
      weekLabels.unshift("Teiken");
      summary[category.name][productType.name][product.name]["weekLabels"] = weekLabels;
    }
    var sorted_summary = {};
    for (const category of productInfo["categories"]) {
      if (category.name in summary) 
        sorted_summary[category.name] = summary[category.name];
    }
    setDisplayProducts(sorted_summary);
    if (Object.keys(summary).length === 0) {
      setProceedBool(true);
    } else {
      setProceedBool(false);
    }
  };

  useEffect(() => {
    if (!productInfo) return;
    if (planProductChange === true) {
      setPlanProductChange(false);
    }
    buildProductInformation();
  }, [productChange, props.planDetails, props.choices, props.products, productInfo]);

  useEffect(() => {
    if (!productInfo) return;
    if (planProductChange === false) return;
    if (planProductChange === true) {
      setPlanProductChange(false);
    }

    buildProductInformation();
  }, [planProductChange]);

  const getProgramPDF = async () => {
    ingredientsUsed.map((ingredientUsed) => {
      let ingredient = ingredients.find((item) => item.id === ingredientUsed.ingredient_id);
      ingredientUsed.ingredient = ingredient;
      return ingredientUsed;
    });

    //create ingredients used pdf labels
    let pdfIngredientsLabels = [];
    if (props.periodsPerCrops) {
      let modifiedWeeklabels = [
        ...props.periodsPerCrops
          .filter((item) => item.crop_id === props.cropId)
          .map((item) => {
            return item.name;
          }),
      ];

      modifiedWeeklabels.unshift("Totaal");
      modifiedWeeklabels.unshift("Aktiewe bestandeel");
      pdfIngredientsLabels = [...modifiedWeeklabels];
    }
  
    const token = localStorage.getItem("agentToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        plan_id: props.planDetails.id,
        program_summary: displayProducts,
        crop_id: props.cropId ?? 1,
        ingedients_used: ingredientsUsed,
        ingredients_labels: pdfIngredientsLabels,
        periods_per_crops: props.periodsPerCrops?.filter((item) => item.crop_id === props.cropId),
      }),
    };

    const response = await fetch("/api/program_pdf", requestOptions);
    const pdf = await response.blob();
    return download(pdf, `Spuitprogram ${props.planDetails.planName}.pdf`);
  };

  if (!displayProducts) return null;

  return (
    <div style={{ width: "100%" }}>
      <Header title={"SPUITPROGRAM"} backPath={"pastOrders"} setPath={props.setPath} />
      <div className="accordion-demo">
        <div>
          <div className="container mt-3 ">
            <div className="row margin-top">
              <div className="col-sm-8">
                <CSSTransition timeout={1000}>
                  <PastOrderCard planDetails={props.planDetails} isHeader={true} />
                </CSSTransition>
              </div>
              <div className="col-sm-4">
                <div className="row justify-content-center">
                  <div className="col-lg-4">
                    <Button
                      className="w-lg-100"
                      label="Gaan voort na bestelling"
                      style={{
                        backgroundColor: "#00a352",
                        borderColor: "#00a352",
                      }}
                      disabled={proceedBool}
                      onClick={() => {
                        planProducts.forEach(item=>{
                        })

                        if(planProducts.every(i=>i.choicesRel?.productsDetailsRel?.isParent)){
                          setShowNotification(true)
                        }
                        else
                          props.setPath("orderView");
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Button
                      className="
                      w-lg-100
                      mt-2 mt-lg-0
                      "
                      label="PDF weergawe"
                      style={{
                        backgroundColor: "#00a352",
                        borderColor: "#00a352",
                      }}
                      disabled={proceedBool}
                      onClick={getProgramPDF}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Button
                      className="w-lg-100 mt-2 mt-lg-0"
                      label="Terug na tuisblad"
                      style={{
                        backgroundColor: "#00a352",
                        borderColor: "#00a352",
                      }}
                      onClick={() => props.setPath("pastOrders")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {/* <h4 style={{ margin: "2%" }}> {props.planDetails.planName}</h4> */}
            {proceedBool ? (
              <></>
            ) : (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "50%",
                  }}
                ></div>
              </>
            )}
          </div>
          <SummaryTable
            displayProducts={displayProducts}
            productChange={productChange}
            setProceedBool={setProceedBool}
            productTypes={productInfo["product_types"]}
            categories={productInfo["categories"]}
            planDetails={props.planDetails}
            products={props.products}
            choices={props.choices}
            periodsPerCrops={props.periodsPerCrops?.filter((item) => item.crop_id === props.cropId)}
            planProducts={planProducts}
            cropId={props.cropId}
            ingredientsUsed={ingredientsUsed}
            ingredients={ingredients}
          />
          <div style={{ display: "none" }}>
            <SummaryTablePrint
              ref={componentRef}
              productChange={productChange}
              productTypes={productInfo["product_types"]}
              categories={productInfo["categories"]}
              planDetails={props.planDetails}
              products={props.products}
              choices={props.choices}
              cropId={props.cropId}
            />
          </div>

          <div className="accordion">
            {productInfo["categories"].map((item, index) => {
              return (
                <ProductSelect
                  key={index}
                  planDetail={props.planDetails}
                  plantingDetail={plantingDetail}
                  category_id={item.id}
                  category_name={item.name}
                  choices={props.choices}
                  productChange={productChange}
                  setProductChange={setProductChange}
                  setPlanProductChange={setPlanProductChange}
                  cropId={props.cropId}
                  periodsPerCrops={props.periodsPerCrops}
                />
              );
            })}
          </div>
        </div>
      </div>
      {showNotification && <Notification message={error} close={() => setShowNotification(false)} />}
      
      
    </div>
  );
};

export default PlanView;
